// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export default {
  apiKey: "AIzaSyB5MGXOvWWvXkLE7xTQGqRTMLjqqSH_vT0",
  authDomain: "groceden-6a330.firebaseapp.com",
  databaseURL: "https://groceden-6a330.firebaseio.com",
  projectId: "groceden-6a330",
  storageBucket: "groceden-6a330.appspot.com",
  messagingSenderId: "184770984436",
  appId: "1:184770984436:web:65509c32a49ebcd88561a1",
  measurementId: "G-GYGV8W6W0Q",



  apiKey: "AIzaSyCexZtzcZ1s4XJMkQWuNdeiQRHwymrxMj8",
  authDomain: "mobile-app-a174e.firebaseapp.com",
  databaseURL: "https://mobile-app-a174e.firebaseio.com",
  projectId: "mobile-app-a174e",
  storageBucket: "mobile-app-a174e.appspot.com",
  messagingSenderId: "678057130111",
  appId: "1:678057130111:web:4ce9aa21ec264da033a74a",
  measurementId: "G-BK4LKXQLLS"
};
