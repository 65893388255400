import getHomeProducts from "./get-home-products";
import getBanners from "./get-banners";
import getWebBanners from './get-web-banners';
import getPositionedBanners from "./get-positioned-banners";
import getWebPositionedBanners from './get-web-positioned-banners';
import getCategories from './getCategories';
import getAllProducts from './get-all-products';
import getRecommendedProducts from './get-recommended-products';
import getWishlistProducts from './get-wishlist-products';

export default {
    getHomeProducts,
    getBanners,
    getWebBanners,
    getPositionedBanners,
    getWebPositionedBanners,
    getCategories,
    getAllProducts,
    getRecommendedProducts,
    getWishlistProducts
}