import service, { serviceTypes } from "../../services";
import AuthState from "../../redux/states/auth";
import CacheState from "../../redux/states/cache";

export default async function getThemeAttributes() {
    try {
        console.log("getting quick seach result");
        const authState = AuthState.get();
        const user = authState.user;
        var serviceType = undefined;
        const cacheState = CacheState.get();
        console.log("Auth State is ", authState);

        //auth setter part with optional test data for guest
        if (typeof user === "object") {
            serviceType = serviceTypes.getThemeAttributes();
            
        } else {
            throw new Error("Login data error, try logout and login again.");
        }
        console.log("Product Service Data is", serviceType);
        const { response, error } = await service(serviceType);
        if (error) {
            //console.log("Received data is ", error.message);
            throw new Error(error.message || "Error occured in quick search");
        } else if (response) {
            
            console.log("MYReceived data is ", response);
            if (response.data) {
                CacheState.set({ webThemes: response.data?.brand_setting });
                const root = document.documentElement;
                root.style.setProperty('--color-ButtonBackRegular', response.data?.brand_setting?.buttonsColor?.regularButtonColor);
      root.style.setProperty('--color-ButtonTextRegular', response.data?.brand_setting?.buttonsColor?.regularTextColor)
      root.style.setProperty('--color-ButtonBackHover', response.data?.brand_setting?.buttonsColor?.hoverButtonColor);
      root.style.setProperty('--color-ButtonTextHover', response.data?.brand_setting?.buttonsColor?.hoverTextColor);
      root.style.setProperty('--color-RegularText', response.data?.brand_setting?.mainTextsColor?.regularColorCode);
      root.style.setProperty('--color-RegularActiveText', response.data?.brand_setting?.mainTextsColor?.activeColorCode);
      // root.style.setProperty('--color-commonBorder', response.data?.brand_setting?.commonBordersColor)
      root.style.setProperty('--color-commonBorder', "#dbdbdb")
      root.style.setProperty('--color-filterBar', response.data?.brand_setting?.productFilterSliderColor)
      root.style.setProperty('--color-wishlistIconColor', response.data?.brand_setting?.buttonsColor?.regularButtonColor)
      root.style.setProperty('--color-wishlistIconText', response.data?.brand_setting?.buttonsColor?.regularTextColor)
      root.style.setProperty('--color-orderDetailsText', response.data?.brand_setting?.profile?.orderDetailColor);
                return response.data?.brand_setting
                
            } else {
                throw new Error("Error occured while getting themes attributes");
            }
        }

    } catch (error) {
        //window.notify([{ message: error.message || "Error occured in refreshing cache", type: "danger" }]);
        return [];
    }
}


