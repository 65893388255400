export default {
  auth: {
    headerText: "Get started and discover the best offers around you",
    otp_sent_email: "OTP sent to your Email",
    verifyOtp: "Verify OTP",
    createPassword: "Create New Password",
    passwordRule: "Enter a passsword with alphabets A-z and a symbol",
    passwordChanged: "Your Password has been changed Successfully.",
    resetPassword: "Reset Password",
    enterEmailOrPhone: "Enter your registered Email, we’ll sent you OTP to reset your password",
    emailRequired: "Email is a required field",
    emailInvalid: "Email must be a valid email",
    enterOTP: "Please Enter OTP",
    enterValidOpt: "Please Enter Valid OTP Number",
    invalidOtp: "OTP is Invalid",
    otpSend: "Send OTP",
    skipAndContinue: "Skip & Continue as Guest",
    facebook: "Facebook",
    google: "Google",
    tandc: "By Signing Up you agree with our",
    signUp: "Sign up",
    signupVia: "or Sign up via",

  },
  home: {},
  profile: {},
  cart: {},
  product: {},
  common: {
    download: "Download the App for Free",
  },
  footer: {
    description: `Lorem Ipsum is simply dummy text of the printing and
    typesetting industry. Lorem Ipsum has been the industry's
    standard dummy text ever since the 1500s`,
    about_us: "About Us",
    delivery_and_returns: "Delivery & Returns",
    contact_us: "Contact Us",
    help_center: "Help Centers",
    faq: "FAQ's",
    t_and_c: "Terms & Conditions",
    privacy_policy: "Privacy Policy",
    copyrights: "Copyright © 2020 Y-Trend, USA. All rights reserved.",
    useful_links: "Useful Links",
    site_map: "Site Map",
    quick_links: "Quick Links",
    promotions: "Promotions",
  },
};
