import React, { useEffect, Fragment, useState } from "react";
import { Alert } from 'reactstrap';
import { useToasts } from 'react-toast-notifications';

export default function NotificationToaster(props) {

    const [ready, setReady] = useState(false);
    const { addToast } = useToasts();

    function pushNotice(notificationArray = []) {

        notificationArray.forEach((item) => {

            //success,error,warning,info
            item.type === "danger" && (item.type = undefined);

            addToast(item.message, { appearance: item.type || 'error' })
        });

    }

    if (!ready) {
        setReady(true);
        window.notify = pushNotice;
    }
    return null
}