//import config from "~/config";
import { ytrendv1 as ytrend } from "../URLConst";
import { ytrend as BASE_URL } from "../URLConst";
import { buildingBucket as myUrl } from '../URLConst';
const config = { apiUrl: ytrend, baseUrl: BASE_URL, buildingUrl: myUrl };

function getToken() {
    return localStorage.getItem('token') ? localStorage.getItem('token') : '';
}

function getUrl(endpoint = "") {
    if (config.apiUrl !== "") {
        if (config.apiUrl.substr(-1) !== "/") {
            config.apiUrl += "/";
        };
    }
    return config.apiUrl + endpoint;
}

function getBaseUrl(endpoint = "") {
    if (config.baseUrl !== "") {
        if (config.baseUrl.substr(-1) !== "/") {
            config.baseUrl += "/";
        };
    }
    return config.baseUrl + endpoint;
}

function getbuildingUrl(endpoint = "") {
    if (config.buildingUrl !== "") {
        if (config.buildingUrl.substr(-1) !== "/") {
            config.buildingUrl += "/";
        }
    }
    return config.buildingUrl + endpoint
}
console.log(getUrl(), getbuildingUrl(), "MYURLS")
//THEME-START
export const getThemeAttributes = () => ({
    method: "GET",
    url: getbuildingUrl(`/store_profile/brand_settings`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
});
//THEME-END

export const getHomeProductListing = () => ({
    method: "GET",
    url: getUrl("products"),
    params: {
        uuid: ""
    },
    body: {},
    headers: {},
    bearerToken: ""
});

export const getAllProducts = () => ({
    method: "GET",
    url: getbuildingUrl(`/catalogue/catalogues`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ""
});

export const getRecommendedProducts = () => ({
    method: "GET",
    url: getbuildingUrl(`/catalogue/catalogues`),
    params: {
        category_id: "",
        recommended: ""
    },
    body: {},
    headers: {},
    bearerToken: ""
});

export const getBanners = () => ({
    method: "GET",
    url: getUrl("products/banners_list"),
    params: {},
    body: {},
    headers: {},
    bearerToken: ""
});

export const getCategories = () => ({
    method: "GET",
    url: getbuildingUrl("categories/categories"),
    params: {},
    body: {},
    headers: {},
    bearerToken: ""
});

export const getWebBanners = () => ({
    method: "GET",
    url: getbuildingUrl("banner/banners/web_banners_list"),
    params: {},
    body: {},
    headers: {},
    bearerToken: ""
});

export const getProductDetails = (product) => ({
    method: "GET",
    url: getbuildingUrl(`/catalogue/catalogues/${product}`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ""
});

export const subscribeForProductNotification = (product) => ({
    method: "POST",
    url: getUrl("products/" + product + "/notify_product"),
    params: {
        uuid: ""
    },
    body: {},
    headers: {},
    bearerToken: ""
});

export const addToWishlist = (userId) => ({
    method: "POST",
    url: getUrl("users/" + userId + "/wishlists"),
    params: {},
    body: {
        product_id: "",
        uuid: ""
    },
    headers: {},
    bearerToken: localStorage.getItem('token') ? localStorage.getItem('token') : ''
});

export const addToWishlistBB = () => ({
    method: "POST",
    url: getbuildingUrl('wishlist/wishlists'),
    params: {},
    body: {
        catalogue_id: ""
    },
    headers: {},
    bearerToken: ""
})

export const removeFromWishlist = (userId) => ({
    method: "DELETE",
    url: getUrl("users/" + userId + "/wishlists"),
    params: {
        product_id: "",
        uuid: ""
    },
    body: {},
    headers: {},
    bearerToken: ""
});

//BB
export const createCart1 = (userId) => ({
    method: "POST",
    url: getbuildingUrl("users/" + (userId || "0") + "/carts"),
    params: {
        uuid: ""
    },
    body: {},
    headers: {},
    bearerToken: "",
});

export const userLogin = () => ({
    method: "POST",
    url: getbuildingUrl("/login/login"),
    params: {},
    body: {},
    headers: {},
    bearerToken: "",
});

export const verifyEmail = (userEmail) => ({
    method: "POST",
    url: getbuildingUrl("/account/accounts/send_otp"),
    params: {},
    body: {
        data: {
            type: "email_account",
            process: "register",
            attributes: {
                email: userEmail
            }
        }

    },
    headers: {},
    bearerToken: ""
})

export const createCart = (userId) => ({
    method: "POST",
    url: getUrl("users/" + (userId || "0") + "/carts"),
    params: {
        uuid: ""
    },
    body: {},
    headers: {},
    bearerToken: "",
});

export const addToCart = (userId, cartId) => ({
    method: "POST",
    url: getUrl(`users/${(userId || "0")}/carts/${(cartId || "0")}/add_cart_item`),
    params: {
        uuid: ""
    },
    body: {
        product_id: "",
        quantity: "",
        product_variant_id: ""
    },
    headers: {},
    bearerToken: ""
});



export const updateInCart = (cartId) => ({
    method: "PUT",
    url: getbuildingUrl(`/cart/carts/${cartId}/update_item_quantity`),
    params: {},
    body: {
        product_id: "",
        quantity: "",
        product_variant: ""
    },
    headers: {},
    bearerToken: ""
});

export const removeFromCart = (cartId) => ({
    method: "DELETE",
    url: getbuildingUrl(`cart/carts/${cartId}/delete_item`),
    params: {
        product_variant_id: "",
        product_id: ""
    },
    body: {},
    headers: {},
    bearerToken: ""
});


export const refreshCart = (userId, cartId) => ({
    method: "GET",
    url: getUrl(`users/${(userId || "0")}/carts/${(cartId || "0")}`),
    params: {
        uuid: ""
    },
    body: {},
    headers: {},
    bearerToken: ""
});


export const deleteCart = (userId, cartId) => ({
    method: "DELETE",
    url: getUrl(`users/${(userId || "0")}/carts/${(cartId || "0")}`),
    params: {
        uuid: ""
    },
    body: {},
    headers: {},
    bearerToken: localStorage.getItem('token') ? localStorage.getItem('token') : ''
});


export const applyCoupon = () => ({
    method: "POST",
    url: getUrl("coupons/apply_coupon"),
    params: {
        uuid: "",
    },
    body: {
        code: "",
        cart_value: "",
        cart_id: ""
    },
    headers: {},
    bearerToken: ""
});
export const applyCouponBB = (cartId) => ({
    method: "POST",
    url: getbuildingUrl(`order_management/orders/${cartId}/apply_coupon/`),
    params: {},
    body: {
        code: "",
        cart_value: ""
    },
    headers: {},
    bearerToken: ""
})
export const removeCoupon = () => ({
    method: "DELETE",
    url: getUrl("coupons/remove_coupon"),
    params: {
        uuid: "",
        cart_id: ""
    },
    body: {},
    headers: {},
    bearerToken: ""
});


export const checkAvailabilityAndBlock = (cartId) => ({
    method: "GET",
    url: getbuildingUrl(`/cart/carts/${cartId}/check_availability`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
});

export const addAddressToCart = (cartId) => ({
    method: "PUT",
    url: getbuildingUrl(`order_management/orders/${cartId}/add_address_to_order`),
    params: {},
    body: {
        // "delivery_address_id":8210,
        "billing_same_as_shipping": false,
        "address":
        {
            "name": "vishash",
            "flat_no": "onchfddfeers1@mailinator.com",
            "address": "hello123",
            "address_line_2": "",
            "zip_code": "454545",
            "phone_number": "1111111111",
            "city": "indore",
            "state": "country",
            "country": "india",
            "is_default": false,
            "billing_address": {
                "name": "vishash",
                "flat_no": "onchfddfeers1@mailinator.com",
                "address": "hello1237645765",
                "address_line_2": "",
                "zip_code": "454545",
                "phone_number": "7894561230",
                "city": "indore",
                "state": "country",
                "country": "india",
                "is_default": false
            }
        }
    },
    headers: {},
    bearerToken: ''
});

//used to place order
export const orderTransactions = () => ({
    method: "POST",
    url: getbuildingUrl(`/order_management/order_transactions`),
    params: {},
    body: {
        cart_id: "",
        is_gift: "",
    },
    headers: {},
    bearerToken: ''
});

//used for hyperpay payments
export const hyperpayDebitCardCheckout = () => ({
    method: "POST",
    url: getUrl(`hyperpay_payments/checkout`),
    params: {},
    body: {
        entityId: "8ac7a4c874672c64017468b0fdcf0756",
        amount: "",
        currency: "SAR",
        paymentType: "DB",
        merchantTransactionId: ""
    },
    headers: {},
    bearerToken: 'OGFjN2E0Yzg3NDY3MmM2NDAxNzQ2OGIwOWFiZjA3NTJ8NkNRQ2M2RkJCdw=='
});


//used for hyperpay payments
export const hyperpayPaymentStatus = () => ({
    method: "GET",
    url: getUrl(`hyperpay_payments/payment_status`),
    params: {
        checkout_id: "",
        entityId: "8ac7a4c874672c64017468b0fdcf0756",
    },
    body: {},
    headers: {},
    bearerToken: 'OGFjN2E0Yzg3NDY3MmM2NDAxNzQ2OGIwOWFiZjA3NTJ8NkNRQ2M2RkJCdw=='
});

//used for hyperpay payments
export const hyperpayOrderConfirm = () => ({
    method: "PUT",
    url: getUrl(`hyperpay_payments/confirm_order`),
    params: {},
    body: {
        cart_id: "",
        checkout_id: ""
    },
    headers: {},
    bearerToken: 'OGFjN2E0Yzg3NDY3MmM2NDAxNzQ2OGIwOWFiZjA3NTJ8NkNRQ2M2RkJCdw=='
});

//use for wishlist listing 
export const getWishlist = () => ({
    method: "GET",
    url: getbuildingUrl(`/wishlist/wishlists`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

//use for my orders list 
export const getMyOrdersList = () => ({
    method: "GET",
    url: getbuildingUrl(`/order_management/orders`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

//use for helpcenter data 
export const getHelpCenterData = () => ({
    method: "GET",
    url: getbuildingUrl(`/help_center/help_centers`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

//use for helpcenter data 
export const getAboutUsData = () => ({
    method: "GET",
    url: getBaseUrl(`static_pages/about-us`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})


export const getQuickSearch = () => ({
    method: "GET",
    url: getbuildingUrl(`/search/search`),
    params: {},
    body: {
        uuid: "",
        query: "",
        per_page: "10"
    },
    headers: {},
    bearerToken: ''
})

export const getSearch = (url) => ({
    method: "GET",
    url: getbuildingUrl(`${url}`),
    params: {},
    headers: {},
    bearerToken: ''
})



export const setRecentSearch = () => ({
    method: "GET",
    url: getUrl(`search/fetch_search_records`),
    params: {
        class_name: "Product",
        class_id: "",//product id
        query: ""
    },
    body: {},
    headers: {},
    bearerToken: ''
})

export const getRecentSearch = () => ({
    method: "GET",
    url: getbuildingUrl(`search/recent_search`),
    params: {
        query: ""
    },
    body: {},
    headers: {},
    bearerToken: ''
})

//use for get address list
export const getAddressList = () => ({
    method: "GET",
    url: getbuildingUrl(`/order_management/addresses`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

//use for update address
export const updateAddress = (addressId) => ({
    method: "PUT",
    url: getbuildingUrl(`order_management/addresses/${addressId}`),
    params: {},
    body: {
        address: {}
    },
    headers: {},
    bearerToken: ''
})

//use for create new address
export const createAddress = (userId) => ({
    method: "POST",
    url: getbuildingUrl(`/order_management/addresses`),
    params: {},
    body: {
        address: {}
    },
    headers: {},
    bearerToken: ''
})

//use for create new address
export const delAddress = (addressId) => ({
    method: "DELETE",
    url: getbuildingUrl(`order_management/addresses/${addressId}`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})


//use for create new address
export const sendContactMessage = () => ({
    method: "POST",
    url: getbuildingUrl(`/contact_us/contacts`),
    params: {},
    body: {
        data: {
            attributes: {
                name: "",
                email: "",
                phone_number: "",
                title: "",
                description: "",
                account_id: ""
            }
        }
    },
    headers: {},
    bearerToken: ''
})


export const createReview = (userId) => ({
    method: "POST",
    url: getbuildingUrl(`/catalogue/reviews`),
    params: {},
    body: {
        comment: "",
        rating: "",
        catalogue_id: ""
    },
    headers: {},
    bearerToken: ''
})

export const getProductReviews = (productId) => ({
    method: "GET",
    url: getbuildingUrl(`/catalogue/reviews/product_reviews?id=${productId}`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})


export const getVariants = (userId) => ({
    method: "GET",
    url: getUrl(`/users/${userId}/variants`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

export const getBrands = () => ({
    method: "GET",
    url: getbuildingUrl('/catalogue/brands'),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

export const getSizes = () => ({
    method: "GET",
    url: getbuildingUrl('/catalogue/catalogues_variants_sizes'),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

export const getTags = () => ({
    method: "GET",
    url: getbuildingUrl('/catalogue/tags'),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

export const getColors = () => ({
    method: "GET",
    url: getbuildingUrl('/catalogue/catalogues_variants_colors'),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

export const updateReview = (productId) => ({
    method: "PUT",
    url: getbuildingUrl(`catalogue/reviews/${productId}`),
    params: {},
    body: {
        rating: "",
        comment: ""
    },
    headers: {},
    bearerToken: ''
})

export const getLogisticDetails = (itemId) => ({
    method: "GET",
    url: getUrl(`shipments/shipment_info?order_id=${itemId}`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

export const getTrackIdDetails = (userId) => ({
    method: "POST",
    url: getUrl(`users/${userId}/carts/track`),
    params: {},
    body: {
        id: "",
        track: "order_item"
    },
    headers: {},
    bearerToken: ''
})

export const updateProfile = (userId) => ({
    method: "PUT",
    url: getUrl(`/users/${userId}`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})


export const refreshProfile = () => ({
    method: "GET",
    url: getbuildingUrl(`/profile/profile`),
    params: {},
    body: {
        token: ""
    },
    headers: {},
    bearerToken: ''
})


export const cancelOrderItem = (userId, cartId) => ({
    method: "PUT",
    url: getbuildingUrl(`order_management/orders/${userId}/cancel_order?item_id=${cartId}`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})


export const getSocialConnects = () => ({
    method: "GET",
    url: getbuildingUrl(`/social_media_account/social_auths`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})


export const removeSocialConnects = (userId, connectId) => ({
    method: "DELETE",
    url: getUrl(`users/${(userId || "0")}/social_auths/${(connectId || "0")}`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

export const checkCartIndex = () => ({
    method: "GET",
    url: getbuildingUrl('/cart/carts'),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})

export const CreateBBCart = () => ({
    method: "POST",
    url: getbuildingUrl('/cart/carts'),
    params: {},
    body: { catalogue_id: "", catalogue_variant_id: "", quantity: "" },
    headers: {},
    bearerToken: ''
})

export const AddtoCartBB = (cartId) => ({
    method: "PUT",
    url: getbuildingUrl(`/cart/carts/${cartId}/add_item`),
    params: {},
    body: { catalogue_id: "", catalogue_variant_id: "", quantity: "" },
    headers: {},
    bearerToken: ''
})

export const removeFromWish = (catalogue_id) => ({
    method: "DELETE",
    url: getbuildingUrl(`/wishlist/wishlists/remove_catalogue/${catalogue_id}`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
})


export const connectSocialAccount = (userId) => ({
    method: "POST",
    url: getUrl(`users/${(userId || "0")}/social_auths/connect`),
    params: {},
    body: {
        provider: "", //google/facebook
        uid: undefined,
        fb_uid: undefined,
        display_name: ""
    },
    headers: {},
    bearerToken: ''
})



export const getCartCurrencies = (userId) => ({
    method: "GET",
    url: getUrl(`users/${(userId || "0")}/carts/get_all_currencies`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
});


export const updateCartCurrency = (userId, cartId) => ({
    method: "PUT",
    url: getUrl(`users/${(userId || "0")}/carts/${(cartId || "0")}/update_currency`),
    params: {},
    body: {
        currency: "SAR"
    },
    headers: {},
    bearerToken: ''
});



export const resendEmailConfirmOTP = (email) => ({
    method: "POST",
    url: getbuildingUrl(`/account/accounts/send_otp`),
    params: {},
    body: {
        data: {
            type: "email_account",
            process: "reset_password",
            attributes: {
                email: email
            }
        }
    },
    headers: {},
    bearerToken: ''
});

export const getFeedbacks = () => ({
    method: "GET",
    url: getBaseUrl(`static_pages/show_feedback`),
    params: {},
    body: {},
    headers: {},
    bearerToken: ''
});