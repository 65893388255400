import { headers } from "./helpers";
import { ytrendv1 } from "../URLConst";
import {buildingBucket} from  '../URLConst';

import axios from "axios";
//BB
export const post1 = async (endpoint, data) =>{
  return await axios.post(`${buildingBucket}${endpoint}`, data,headers)
}
export const put1 = async (endpoint, data) => {
  return await axios.put(`${buildingBucket}${endpoint}`, data, headers);
};
export const get1 = async (endpoint, paramHeader) => {
  console.log(paramHeader,"param**");
  return await axios.get(`${buildingBucket}${endpoint}`,paramHeader);
};
//BB
export const post = async (endpoint, data) => {
  return await axios.post(`${ytrendv1}${endpoint}`, data, headers);
};
export const put = async (endpoint, data) => {
  return await axios.put(`${ytrendv1}${endpoint}`, data, headers);
};
export const get = async (endpoint, paramHeader) => {
  return await axios.get(`${ytrendv1}${endpoint}`, paramHeader? paramHeader : headers);
};
export const getProducts = async (endpoint, header) => {
  // console.log(header, "headers");
  return await axios.get(`${buildingBucket}${endpoint}`, header);
};
export const putUpdate = async (endpoint, data, header) => {
  return await axios.put(`${buildingBucket}${endpoint}`, data, header);
};

export const putUpdate1 = async (endpoint, data, header) => {
  return await axios.put(`${buildingBucket}${endpoint}`, data, headers);
};
export const postUpdate = async (endpoint, data, header) => {
  return await axios.post(`${ytrendv1}${endpoint}`, data, header);
};
export const deleteData = async (endpoint, header) => {
  return await axios.delete(`${ytrendv1}${endpoint}`, header);
};
// export default post, put ;
// export default apiHelper;
