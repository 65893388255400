import React, { useRef, useState, useEffect, Fragment } from "react";
import "./css/index.scoped.css";
import { Container, Row, Col, Button } from "reactstrap";
import { FaHeart } from "react-icons/fa";
import { AllModal } from "../../../components";
import NoWishlist from "./no-wishlist";
import commands from "../../../commands";
import { useHistory } from "react-router-dom";
import service, { serviceTypes } from "../../../services"
import AuthState from "../../../redux/states/auth";
import { FavouriteProductSet } from "../../index";
import langg from "../../../language";
import { useSelector } from "react-redux";

function WhishListProductComponent({ products, ...props }) {
  console.log(products, "MYPORDUCTSWISHLIST");
  const productList = products;
  const [ShowWLModal, setShowWLModal] = useState(false);
  const openRemoveWhishListModal = () => setShowWLModal(!ShowWLModal);
  const myCurrency = useSelector((state) => state.cache?.webThemes?.ExtraFields?.currency_type)
  const history = useHistory();
  const state = AuthState.get();
  const lang = new langg("common");

  function addToWishlist(product) {
  const serviceType = serviceTypes.addToWishlistBB();
    serviceType.body.catalogue_id = parseInt(product?.data?.attributes?.id?.data?.id)
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data) {
          window.notify([{ message: "Product added to wishlist successfully", type: "success" }]);
          commands.cache.getWishlistProducts()
          commands.cache.getRecommendedProducts()
          props.getWishlist();

        }
      })
      .finally(commands.user.refreshProfile);
  }

  function removeFromWishlist(product) {
    console.log("Removing from wishlist", product);

    const serviceType = serviceTypes.removeFromWish(product?.data?.attributes?.id?.data?.id);
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data) {
          console.log(response?.data, "WISHLIST");
          window.notify([{ message: "Product removed from wishlist successfully", type: "danger" }]);
          commands.cache.getWishlistProducts()
          commands.cache.getRecommendedProducts()
          props.getWishlist();
        }
      })
      .finally(commands.user.refreshProfile);


  }

  return (
    <div className="profile-pg-whish-lt-inner-wrap profile-pg-inner-wrap bg-white radius-10 profile-pg-mb-30 profile-p-30">
      <div className="profile-pg-inner-wrapper">
        <div className="profile-tab-content">
          <div class="profile-pg-wl-allproduct-main-wrap">
            {ShowWLModal ? <AllModal modalName="removewhishlist" /> : ""}
            <Row className="profile-pg-wl-cm-row-margin">

              {productList.map((product, index) => {
                if (!(Array.isArray(product?.data?.attributes?.id?.data?.attributes?.catalogue_variants) && product?.data?.attributes?.id?.data?.attributes?.catalogue_variants > 0)) {
                  return (
                    <Col md={6} lg={4} className="px-2 col-xxl-4 yt-cm-wl-col">
                      <div class="product profile-pg-wl-sgl-product-cpnt text-center mb-4">
                        <div class="d-flex justify-content-between align-items-center mt-3 yt-top-offer-whl">
                          {product?.data?.attributes?.id?.data?.attributes?.on_sale ? (<div class="text-center p-1 yt-product-off">Sale</div>) : <div className="text-center p-1" />}
                          <div class="text-right pr-2">
                            <FavouriteProductSet onDataId={index} onPageType="shop" isFav={product?.data?.attributes?.id?.data?.attributes?.wishlisted} onClick={() => product?.data?.attributes?.id?.data?.attributes?.wishlisted ? removeFromWishlist(product) : addToWishlist(product)} />
                          </div>
                        </div>
                        <div onClick={() => history.push("/shop/" + product?.data?.attributes?.id?.data?.id)} className="w3-ripple" style={{ cursor: "pointer" }}>
                          <img
                            src={product?.data?.attributes?.id?.data?.attributes?.images?.data[0]?.attributes?.url}
                            class="yt-product-bg-image img-fluid"
                            alt="ytrend"
                          />
                          <div class="mt-3 profile-pg-wl-sgl-product-title text-center">
                            {product?.data?.attributes?.id?.data?.attributes?.name}
                          </div>
                          <div className="price profile-pg-wl-sgl-price-wrap text-center ">
                            {product?.data?.attributes?.id?.data?.attributes?.on_sale && (<><span className="price1 profile-pg-wl-sgl-product-sale-price">
                              {myCurrency} {product?.data?.attributes?.id?.data?.attributes?.sale_price}
                            </span>
                              <span className="price2 profile-pg-wl-sgl-product-reg-price2">
                                {product?.data?.attributes?.id?.data?.attributes?.price}
                              </span></>)}
                            {!product?.data?.attributes?.id?.data?.attributes?.on_sale && <span className="price1 profile-pg-wl-sgl-product-sale-price">
                              {myCurrency} {product?.data?.attributes?.id?.data?.attributes?.price}
                            </span>}
                          </div>

                        </div>
                        {/*
                        <Button color="secondary profile-pg-wl-sgl-product-add-btn buttoncart py-3" onClick={() => commands.cart.addItem({ productId: product.id, quantity: 1, onSuccess: () => props.getWishlist() })}>
                          {product.is_in_cart ? "Already in Cart" : "Add to Cart"}
                  </Button>*/}
                        {(product?.data?.attributes?.id?.data?.attributes?.stock_qty >= 1 && product?.data?.attributes?.id?.data?.attributes?.availability === "in_stock") ?
                          <Fragment>
                            {product?.data?.attributes?.id?.data?.attributes?.cart_quantity > 0 &&
                              <Button color=" profile-pg-wl-sgl-product-add-btn buttoncart py-3" onClick={() => history.push("/cart")} >
                                {lang.get("goToCart", "Go to Cart")}
                              </Button>
                            }
                            {product?.data?.attributes?.id?.data?.attributes?.cart_quantity === null &&
                              <Button color=" profile-pg-wl-sgl-product-add-btn buttoncart py-3" onClick={() => commands.cart.getCartIndex({ productId: product?.data?.attributes?.id?.data?.id, variantId: product?.data?.attributes?.id?.data?.attributes?.catalogue_variants[0]?.id, quantity: 1, onSuccess: () => props.getWishlist() })}>
                                {lang.get("addToCart", "Add to Cart")}
                              </Button>
                            }
                          </Fragment>
                          :
                          <Button disabled color=" profile-pg-wl-sgl-product-add-btn buttoncart py-3" >
                            {lang.get("outOfStock", "Out of Stock")}
                          </Button>
                        }

                      </div>
                    </Col>
                  );
                }




                return product?.data?.attributes?.id?.data?.attributes?.catalogue_variants?.map((value, index) => value?.attributes.is_default && (
                  <Col md={6} lg={4} className="px-2 col-xxl-4 yt-cm-wl-col">
                    <div class="product profile-pg-wl-sgl-product-cpnt text-center mb-4">
                      <div class="d-flex justify-content-between align-items-center mt-3 yt-top-offer-whl">
                        {value.on_sale && false ? (<div class="text-center p-1 yt-product-off">Sale</div>) : <div className="text-center p-1" />}
                        <div class="text-right pr-2">
                          <FavouriteProductSet onDataId={index} onPageType="shop" isFav={product?.data?.attributes?.id?.data?.attributes.wishlisted} onClick={() => product?.data?.attributes?.id?.data?.attributes.wishlisted ? removeFromWishlist(product) : addToWishlist(product)} />
                        </div>
                      </div>
                      <div onClick={() => history.push("/shop/" + product?.data?.attributes?.id?.data?.id)} className="w3-ripple" style={{ cursor: "pointer" }}>
                        <img
                          src={product?.data?.attributes?.id?.data?.attributes?.images?.data[0]?.attributes?.url}
                          class="yt-product-bg-image img-fluid"
                          alt="ytrend"
                        />
                        <div class="mt-3 profile-pg-wl-sgl-product-title text-center">
                          {product?.data?.attributes?.id?.data?.attributes?.name}
                        </div>

                        <div className="price profile-pg-wl-sgl-price-wrap text-center ">
                          {value?.attributes?.on_sale && (<><span className="price1 profile-pg-wl-sgl-product-sale-price">
                            {value?.attributes?.sale_price}
                          </span>
                            <span className="price2 profile-pg-wl-sgl-product-reg-price2">
                              {value?.attributes?.price}
                            </span></>)}
                          {!value.on_sale && <span className="price1 profile-pg-wl-sgl-product-sale-price">
                            {value?.attributes?.actual_price}
                          </span>}
                        </div>
                      </div>
                      {/*
                      <Button color="secondary profile-pg-wl-sgl-product-add-btn buttoncart py-3" onClick={() => commands.cart.addItem({ productId: value.id, quantity: 1, onSuccess: () => props.getWishlist() })}>
                        {value.is_in_cart ? "Already in Cart" : "Add to Cart"}
                </Button>*/}
                      {(value?.attributes.stock_qty >= 0) ?
                        <Fragment>
                          {product?.data?.attributes.cart_quantity &&
                            <Button color=" profile-pg-wl-sgl-product-add-btn buttoncart py-3" onClick={() => history.push("/cart")}>
                              {lang.get("goToCart", "Go to Cart")}
                            </Button>
                          }
                          {value?.attributes.stock_qty === null &&
                            <Button color=" profile-pg-wl-sgl-product-add-btn buttoncart py-3" onClick={() => commands.cart.getCartIndex({ productId: product?.data?.attributes?.id?.data?.id, variantId: value?.attributes?.id, quantity: 1, onSuccess: () => props.getWishlist() })}>
                              {lang.get("addToCart", "Add to Cart")}
                            </Button>
                          }
                        </Fragment>
                        :
                        <Button disabled color=" profile-pg-wl-sgl-product-add-btn buttoncart py-3" >
                          {lang.get("outOfStock", "Out of Stock")}
                        </Button>
                      }
                    </div>
                  </Col>
                ));

              })}



            </Row>
          </div>
        </div></div></div>
  );
}
function UserWhishList({ products, ...props }) {
  console.log(products?.attributes?.wishlist_items, "MYPRODUCS-S123");
  return Array.isArray(products?.attributes?.wishlist_items) && products?.attributes?.wishlist_items.length > 0 ?
    <WhishListProductComponent products={products?.attributes?.wishlist_items} {...props} />
    :
    <NoWishlist />;
}
export default UserWhishList;
