import service, { serviceTypes } from "../../services";
import AuthState from "../../redux/states/auth";
import _ from "lodash";

export default async function userLogin(data ) {
    try {
        const authState = AuthState.get();
        const loginData = authState.loginData;
        var serviceType = undefined;
        const user = authState.user;

        //auth setter part with optional test data for guest
        if (typeof user === "object") {
            serviceType = serviceTypes.userLogin();
        } else {
            throw new Error("Login data error, try logout and login again.");
        }

        if (serviceType) {
            serviceType.body.email_or_mobile = data.email;
            serviceType.body.password = data.password;
        }

        const { response, error } = await service(serviceType);
        if (error) {
            // console.log("Received data is ", error);
            throw new Error("The email or password you've entered is incorrect.");
        } else if (response) {
            //console.log("Received data is ", response);
            if (response.data) {
                window.notify([{ message: response.data.meta?.message || "Address added successfully", type: "success" }]);
                return response.data
            } else {
                throw new Error(response.meta?.message || "Error occured while Logging in");
            }
        }else{
            log
        }

    } catch (error) {
        window.notify([{ message: error.message || "Error occured while logging in", type: "danger" }]);
    }
}


