import getQuickSearch from "./get-quick-search";
import getSearch from './get-search';
import createReview from "./createReview";
import updateReview from "./updateReview";
import getVariants from "./get-variants";
import getBrands from './get-brands';
import getColors from './get-color';
import getSizes from './get-sizes';
import getTags from './get-tags';
import getRecentSearches from "./get-recent-searches";
import setRecentSearches from "./set-recent-searches";
import getReviews from "./get-reviews";

export default {
    getQuickSearch,
    getSearch,
    createReview,
    updateReview,
    getReviews,
    getVariants,
    getRecentSearches,
    setRecentSearches,
    getBrands,
    getColors,
    getSizes,
    getTags
}