import service, { serviceTypes } from "../../services";
import CartState from "../../redux/states/cart";
import AuthState from "../../redux/states/auth";
import CacheState from "../../redux/states/cache";

export default async function checkUserCart() {
    try {
        console.log("getting the banners");
        const cartState = CartState.get();
        const authState = AuthState.get();
        const cacheState = CacheState.get();
        const loginData = authState.loginData;
        const user = authState.user;

        var serviceType = undefined;
        console.log("Auth State is ", authState);

        //auth setter part with optional test data for guest
        if (typeof user === "object") {
            serviceType = serviceTypes.checkCartIndex();
        } else {
            throw new Error("Login data error, try logout and login again.");
        }

        console.log("Cache Service Data is", serviceType);
        const { response, error } = await service(serviceType);
        if (error) {
            console.log("Received data is ", error.request);
            throw new Error(error.message || "Error occured in adding to cart");
        } else if (response) {
            //console.log("Received data is ", response);
            if (response.data?.data?.length !== undefined) {
                CartState.set({ myCart: response?.data?.data[0]?.attributes });
            } else if (response?.data?.message === "No order record found.") {
                //NEW USER WITH NO CART
                CartState.set({ myCart: {} });
               
            } else {
                throw new Error("Error occured in adding to cart");
            }
        }

    } catch (error) {
        window.notify([{ message: error.message || "Error occured in adding to cart", type: "danger" }]);

    }
}


