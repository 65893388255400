import React, { useRef, useState, useEffect, Fragment } from "react";
import "./css/index.scoped.css";
import { Container, Row, Col, Button, Spinner } from "reactstrap";
import { FaRegHeart } from "react-icons/fa";
import Carousel from "react-elastic-carousel";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { FavouriteProductSet } from "../../components";
import { useHistory } from "react-router";
import commands from "../../commands";
import service, { serviceTypes } from "../../services"
import AuthState from "../../redux/states/auth";
import CacheState from "../../redux/states/cache";
import langg from "../../language";
import { useSelector } from "react-redux";

function TitleBar(props) {
  const lang = new langg("common");
  if (props.name != undefined) {
    return (
      <div className="yt-produstslider-info d-flex justify-content-between align-items-center">
        <h4 className="yt-comonent-top-title my-0">{props.name}</h4>
        <div className="yt-comonent-link">
          <Button color="link yt-component-more px-0" onClick={props.onViewMore}>{lang.get("viewMore", "View More")}</Button>
        </div>
      </div>
    );
  } else {
    return <></>;
  }
}

function ProductCard(props) {
  const history = useHistory();
  const state = AuthState.get();
  const [products, setProducts] = useState(undefined);
  const lang = new langg("common");
  const myCurrency = useSelector((state) => state.cache?.webThemes?.ExtraFields?.currency_type)
  let carousel = useRef();
  let p = 0;
  let Breakpoints = [
    { width: 320, itemsToShow: 2, itemsToScroll: 2 },
    { width: 730, itemsToShow: 4, itemsToScroll: 4 },
    { width: 1300, itemsToShow: 5 },
  ];

  useEffect(() => {
    // if(props.name=="Today’s Deals"){
    if (props.products?.length > 0) {
      setProducts(props.products);
    }
    // }
  }, [props]);

  useEffect(() => {
    // console.log(products, "products");
  }, [products]);

  const productDetails = (a) => {
    console.log(a);
    history.push({ pathname: `/shop/${a.id}`, state: { a } });
  };

  function addToWishlist(product) {
    const serviceType = serviceTypes.addToWishlistBB();
    serviceType.body.catalogue_id = parseInt(product.id)
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data) {
          window.notify([{ message: "Product added to wishlist successfully", type: "success" }]);
          // commands.cache.getHomeProducts();
          commands.cache.getWishlistProducts()
          commands.cache.getRecommendedProducts()
        }
      })
    .finally(commands.user.refreshProfile);

  }

  function removeFromWishlist(product) {
    const serviceType = serviceTypes.removeFromWish(product.id)
    service(serviceType)
      .then(({ response, error }) => {
        if (error) {
          window.notify([{ message: error.message }]);
        } else if (response?.data) {
          window.notify([{ message: "Product removed from wishlist successfully", type: "danger" }]);
          commands.cache.getWishlistProducts()
          commands.cache.getRecommendedProducts()
        }

      })
    .finally(commands.user.refreshProfile);
  }

  function getList() {
    
    var list = [];
    var lolist = [];

    products.forEach((product, index) => {
      var percentageValue = parseInt((product.attributes.price - parseInt(product.attributes.sale_price)) / product.attributes.price * 100);
      if (!(Array.isArray(product.attributes.catalogue_variants) && product.attributes.catalogue_variants.length > 0)) {
        list.push(
          <div className="yt-item-slider" >
            <div className="product yt-product-slider-cpnt text-center">
              <div className="d-flex justify-content-between align-items-center mt-3 yt-top-offer-whl">
                {product.attributes.on_sale &&
                  <div className="yt-product-off text-center p-1">
                    {percentageValue < 1 ?
                      "sale"
                      :
                      "-" + parseInt((product.attributes.price - parseInt(product.attributes.sale_price)) / product.attributes.price * 100) + "%"
                    }

                  </div>
                }
                {!product.attributes.on_sale && <div className="text-center p-1" />}
                <div className="text-right mr-3">
                  <FavouriteProductSet onDataId={index} onPageType="shop" isFav={product.attributes.wishlisted} onClick={() => product.attributes.wishlisted ? removeFromWishlist(product) : addToWishlist(product)} />
                </div>
              </div>
              <img
                src={product.attributes.images.data.length > 0 ? product.attributes.images.data[0].attributes.url : "/images/thumb/missing.png"}
                className=" img-fluid yt-td-product-img prodimage w3-ripple "
                alt="BuildingBlock"
                onClick={() => productDetails(product)}
              />
              <div className="mt-4 yt-product-title text-center">
                {product.attributes.name}
              </div>
              <div className="price yt-price-wrap text-center pt-3 pb-4">
                {product.attributes.on_sale && (<><span className="price1 yt-product-sale-price">
                  {myCurrency} {product.attributes.sale_price}
                </span>
                  <span className="price2 yt-product-reg-price2">
                    {myCurrency} {product.attributes.price}
                  </span></>)}
                {!product.on_sale && <span className="price1 yt-product-sale-price">
                  {myCurrency} {product.attributes.price}
                </span>}
              </div>
              {(product.attributes.stock_qty >= 1 && product.attributes.current_availibility === "in_stock") ?
                <Fragment>
                  {product.attributes.cart_quantity > 0 &&
                    <Button color=" yt-product-add-btn buttoncart w3-ripple py-3" onClick={() => history.push("/cart")} >
                      {lang.get("goToCart", "Go to Cart")}
                    </Button>
                  }
                  {product.attributes.cart_quantity === null &&
                    <Button color=" yt-product-add-btn buttoncart w3-ripple py-3" onClick={() => commands.cart.getCartIndex({ productId: product.id,productVariantId: product?.attributes?.default_variant?.id, quantity: 1, onSuccess: () => commands.cache.getRecommendedProducts() })}>
                      {lang.get("addToCart", "Add to Cart")}
                    </Button>
                  }
                </Fragment>
                :
                <Button disabled color=" yt-product-add-btn buttoncart w3-ripple py-3" onClick={() => commands.cart.getCartIndex({ productId: product.id,productVariantId: product?.attributes?.default_variant?.id, quantity: 1, onSuccess: () => commands.cache.getRecommendedProducts() })}>
                  {lang.get("outOfStock", "Out of Stock")}
                </Button>
              }
            </div>
          </div>
        );
      }

      product?.attributes.catalogue_variants?.forEach((x, i) => x.attributes.is_default && list.push(
        <div className="yt-item-slider" popkey={index + "-" + i}>
          <div className="product yt-product-slider-cpnt text-center">
            <div className="d-flex justify-content-between align-items-center mt-3 yt-top-offer-whl">
              {x.attributes.on_sale && <div className="yt-product-off text-center p-1">
                -{parseInt((x.attributes.price - x.attributes.sale_price) / x.attributes.price * 100)}%
                </div>}
              {!x.on_sale && <div className="text-center p-1" />}
              <div className="text-right mr-3">
                <FavouriteProductSet onDataId={index} onPageType="shop" isFav={product.attributes.wishlisted} onClick={() => product.attributes.wishlisted ? removeFromWishlist(product) : addToWishlist(product)} />
              </div>
            </div>
            <img
              src={x.attributes?.images?.data?.length > 0 ? x.attributes?.images.data[0].attributes.url : x.attributes.images.data[0].attributes.url}
              className="img-fluid yt-td-product-img prodimage w3-ripple "
              alt="BuildingBlock"
              onClick={() => productDetails(product)}
            />
            <div className="mt-4 yt-product-title text-center">
              {product.attributes.name}
            </div>
            <div className="price yt-price-wrap text-center pt-3 pb-4">
              {x?.attributes?.on_sale && (<><span className="price1 yt-product-sale-price">
                {myCurrency} {x.attributes.sale_price}
              </span>
                <span className="price2 yt-product-reg-price2">
                  <del>{myCurrency} {x.attributes.price}</del>
                </span></>)}
              {!x?.attributes.on_sale && <span className="price1 yt-product-sale-price">
                {myCurrency} {x.attributes.price}
              </span>}
            </div>
            {(x.attributes.stock_qty >= 1) ?
              <Fragment>
                {product.attributes.cart_quantity > 0 &&
                  <Button color=" yt-product-add-btn buttoncart w3-ripple py-3 my-product-button" onClick={() => history.push("/cart")}>
                    {lang.get("goToCart", "Go to Cart")}
                  </Button>
                }
                {product.attributes.cart_quantity === null &&
                  <Button color=" yt-product-add-btn buttoncart w3-ripple py-3 my-product-button" onClick={() => commands.cart.getCartIndex({ productId: product.id, variantId: x.id, quantity: 1, onSuccess: () => commands.cache.getRecommendedProducts() })}>
                    {lang.get("addToCart", "Add to Cart")}
                  </Button>
                }
              </Fragment>
              :
              <Button disabled color=" yt-product-add-btn buttoncart w3-ripple py-3 my-product-button" >
                {lang.get("outOfStock", "Out of Stock")}
              </Button>
            }
          </div>
        </div>
      ));
      list = [...list, ...lolist];
    });

    return list;
  }

  var list = [];
  if (products && Array.isArray(products) && products.length > 0) {
    list = getList();
  }
  const showCard = list.length > 0;

  return (products && Array.isArray(products) && products.length > 0) ? (
    <section className="hp-product-slider" style={showCard ? {} : { display: "none" }}>
      <Container>
        <TitleBar name={props.name} onViewMore={props.onViewMore} />
        <div className="yt-component-wrapper yt-slider-component bg-white py-4 mb-3">
          <IoIosArrowDropleft
            className="yt-slider-left img-fluid"
            onClick={() => carousel.slidePrev()}
          />
          <IoIosArrowDropright
            className="yt-slider-right img-fluid"
            width="20"
            height="20"
            onClick={() => carousel.slideNext()}
          />
          <Carousel
            itemsToShow={15}
            itemsToScroll={15}
            pagination={false}
            showArrows={false}
            ref={(ref) => (carousel = ref)}
            breakPoints={Breakpoints}
          >
            {list}
          </Carousel>
        </div>
      </Container>
    </section>
  )
    :
    null;
}

export default ProductCard;
