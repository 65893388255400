import service, { serviceTypes } from "../../services";
import CartState from "../../redux/states/cart";
import AuthState from "../../redux/states/auth";
import CacheState from "../../redux/states/cache";

export default async function getCartIndex({ productId, variantId, quantity, onSuccess }) {
    try {
        const cartState = CartState.get();
        const authState = AuthState.get();
        const cacheState = CacheState.get();
        const loginData = authState.loginData;
        const user = authState.user;

        var serviceType = undefined;
        var serviceType1 = undefined;
        var serviceType2 = undefined;
        var cartId = undefined;

        //auth setter part with optional test data for guest
        if (typeof user === "object") {
            serviceType = serviceTypes.checkCartIndex();
        } else {
            throw new Error("Login data error, try logout and login again.");
        }

        console.log("Cache Service Data is", serviceType);
        const { response, error } = await service(serviceType);
        if (error) {
            console.log("Received data is ", error.request);
            throw new Error(error.message || "Error occured in adding to cart");
        } else if (response) {
            //console.log("Received data is ", response);
            
            if(response?.data?.data){
                cartId = response?.data?.data[0]?.id
            }
            if (response.data?.data) {
                serviceType2 = serviceTypes.AddtoCartBB(cartId);
                if(serviceType2){
                    serviceType2.body.catalogue_id = productId;
                    serviceType2.body.catalogue_variant_id = variantId;
                    serviceType2.body.quantity = quantity;
                }
                const { response, error } = await service(serviceType2)
                if(error){
                    throw new Error(error?.message || "Error occured in adding to cart");
                }else if(response?.status === 200){
                    window.notify([{message: "Product added to cart successfully", type: "success"}])
                    CartState.set({ cart: response.data });
                    (typeof onSuccess === "function") && onSuccess()
                }
                // CacheState.set({ webbanners: response.data?.data?.banners?.data });
            } else if (response?.data?.errors[0]?.message === "No order record found.") {
                serviceType1 = serviceTypes.CreateBBCart();
                if (serviceType1) {
                    serviceType1.body.catalogue_id = productId;
                    serviceType1.body.catalogue_variant_id = variantId;
                    serviceType1.body.quantity = quantity;
                }
                const { response, error } = await service(serviceType1)
                if(error){    
                    throw new Error(error?.message || "Error occured in adding to cart");
                }else if(response?.status === 200){
                    window.notify([{ message: "Product added to cart successfully", type: "success" }])
                    CartState.set({ cart: response.data });
                    (typeof onSuccess === "function") && onSuccess();
                }
            } else {
                throw new Error("Error occured in adding to cart");
            }
        }

    } catch (error) {
        window.notify([{ message: error.message || "Error occured in adding to cart", type: "danger" }]);

    }
}


